import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { Entries } from "../utils/utils.types";

import { tShipment, tShipments } from "./types/app.types";

type CacheType = {
  shipments: tShipments;
};

const CacheInitialState: CacheType = {
  shipments: [],
};

const cacheSlice = createSlice({
  name: "cache",
  initialState: getState<CacheType>("cache", CacheInitialState),
  reducers: {
    updateShipment(state, { payload }: PayloadAction<tShipment>) {
      state.shipments = [
        payload,
        ...state.shipments
          .filter((shipment) => shipment._id !== payload._id)
          .slice(0, 4),
      ];

      saveState("cache", current(state));
    },
    clearcache(state) {
      for (const [k, v] of Object.entries(
        CacheInitialState
      ) as Entries<CacheType>) {
        state[k] = v as any; // Hmm
      }

      saveState("cache", current(state));
    },
  },
});

export const { updateShipment, clearcache } = cacheSlice.actions;

export default cacheSlice.reducer;

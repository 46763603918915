import { MONTHS_SHORT } from "../data";

export const getDateTime = (date_string: string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const hours = new_date.getHours();
  const meridian = hours < 12 ? "AM" : "PM";

  let hours12format = hours % 12;
  hours12format = hours12format ? hours12format : 12;

  const comp_date = `${day} ${MONTHS_SHORT[month]} ${year}`;
  const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
    new_date.getMinutes()
  ).padStart(2, "0")} ${meridian}`;

  return `${comp_date} ${comp_time}`;
};

export const getDateTime2 = (date_string: string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const hours = new_date.getHours();
  const meridian = hours < 12 ? "AM" : "PM";

  let hours12format = hours % 12;
  hours12format = hours12format ? hours12format : 12;

  const comp_date = `${day} ${MONTHS_SHORT[month]} ${year}`;
  const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
    new_date.getMinutes()
  ).padStart(2, "0")} ${meridian}`;

  return [comp_date, comp_time];
};

export const getDate = (date_string: string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const comp_date = `${day} ${MONTHS_SHORT[month]}, ${year}`;

  return `${comp_date}`;
};

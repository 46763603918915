import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

import Home from "./pages/Home/Home";
import Tracking from "./pages/Tracking/Tracking";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<Tracking />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { callOutline, informationCircle, mailOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tShipment } from "../../store/types/app.types";
import { updateShipment } from "../../store/cacheReducer";

import VerticalBarLoader from "../../components/VerticalBarLoader/VerticalBarLoader";

import { getDate, getDateTime2 } from "../../utils/func";

import dmxLogo from "../../assets/img/dmx-logo-colored.png";

const Tracking = () => {
  const { id: shipmentId } = useParams();

  const dispatch = useDispatch();

  const shipments = useSelector((state: tRootState) => state.cache.shipments);

  const shipment: tShipment | null =
    shipments.find(
      (ship) =>
        ship._id === shipmentId || ship.TrackingNumber.toString() === shipmentId
    ) || null;

  const [error, setError] = useState(false);

  useEffect(() => {
    api_client({
      url: `/shipments/${shipmentId}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(updateShipment(res.data.data));
      })
      .catch((err) => {
        // navigate to error page
        setError(true);
      })
      .finally(() => {});
  }, [shipmentId, dispatch]);

  return (
    <>
      <div className="auth__right tracking">
        <div className="auth__main auth__main--1" style={{ height: "auto" }}>
          <div className="auth__logo-box">
            <img src={dmxLogo} alt="" className="auth__logo auth__logo--sm" />
          </div>
          <div className="auth__btns">
            <Link to="/" className="auth__btn">
              New Tracking
            </Link>
            <Link to="#" className="auth__btn auth__btn--active">
              Shipment
            </Link>
          </div>
        </div>
        <main className="main">
          <div className="container">
            {!error && !shipment ? <VerticalBarLoader /> : null}
            {shipment ? (
              <>
                <div className="waybill-header">
                  <h1>Tracking Information</h1>
                </div>
                <div className="waybill">
                  <div className="waybill__block">
                    <div>
                      <p className="waybill__heading">
                        {shipment.DHLTrackingNumber
                          ? "Waybill Number"
                          : "Shipment Number"}
                        : {shipment.DHLTrackingNumber || shipment._id}
                      </p>
                      <p>Shipment Date: {getDate(shipment.PickupDate)}</p>
                    </div>
                    <div>
                      <p>
                        <strong>Status:</strong> {shipment.Status}
                      </p>
                      {shipment.Rider ? (
                        <div className="waybill__rider">
                          <img
                            src={shipment.Rider.ProfilePicturePath}
                            alt=""
                            className="waybill__rider-img"
                          />
                          <div className="waybill__rider-infos">
                            <p className="waybill__rider-name">
                              {shipment.Rider.FirstName}{" "}
                              {shipment.Rider.LastName}
                            </p>
                            <p className="waybill__rider-info">
                              <IonIcon icon={callOutline} />
                              <span>{shipment.Rider.Telephone}</span>
                            </p>
                            <p className="waybill__rider-info">
                              <IonIcon icon={mailOutline} />
                              <span>{shipment.Rider.EmailAddress}</span>
                            </p>
                          </div>
                          <button className="badge btn--info">Rider</button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="waybill__block">
                    <div className="waybill__info">
                      <div>
                        <p className="waybill__heading">Ship From</p>
                      </div>
                      <div>
                        <p>{shipment.Shipper.Address}</p>
                        {shipment.Shipper.Address2 ? (
                          <p>{shipment.Shipper.Address2}</p>
                        ) : null}
                        {shipment.Shipper.Address3 ? (
                          <p>{shipment.Shipper.Address3}</p>
                        ) : null}
                        <p>
                          {shipment.Shipper.City}, {shipment.Shipper.State}
                        </p>
                        <p>{shipment.Shipper.Country.Name}</p>
                      </div>
                    </div>
                    <div className="waybill__info">
                      <div>
                        <p className="waybill__heading">Ship To</p>
                      </div>
                      <div>
                        <p>{shipment.Cosignee.Address}</p>
                        {shipment.Cosignee.Address2 ? (
                          <p>{shipment.Cosignee.Address2}</p>
                        ) : null}
                        {shipment.Cosignee.Address3 ? (
                          <p>{shipment.Cosignee.Address3}</p>
                        ) : null}
                        <p>
                          {shipment.Cosignee.City}, {shipment.Cosignee.State}
                        </p>
                        <p>{shipment.Cosignee.Country.Name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="waybill__block">
                    <div>
                      <p className="waybill__heading">Shipment Details</p>
                      <div className="waybill__detail">
                        <p>Shipment Type</p>
                        <p>{shipment.Type}</p>
                      </div>
                      {shipment.Type !== "Local" ? (
                        <>
                          <div className="waybill__detail">
                            <p>Number of pieces</p>
                            <p>{shipment.TotalPCS}</p>
                          </div>
                          <div className="waybill__detail">
                            <p>Total weight</p>
                            <p>{shipment.TotalWeight}KG</p>
                          </div>
                          <div className="waybill__detail">
                            <p>Volumetric weight</p>
                            <p>0.00KG</p>
                          </div>
                          <div className="waybill__detail">
                            <p>Chargeable weight</p>
                            <p>{shipment.TotalWeight}KG</p>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="waybill__info">
                      <div>
                        <p className="waybill__heading">
                          Description of Contents
                        </p>
                        <p>{shipment.Description}</p>
                      </div>
                    </div>
                    {shipment.Type !== "Local" ? (
                      <>
                        <div className="table-responsive table-responsive--1 grid-full">
                          <table className="table table--bordered">
                            <thead>
                              <tr>
                                <th className="text-center">S/N</th>
                                <th className="text-center">Item</th>
                                <th className="text-center">Weight (Kg)</th>
                                <th className="text-center">Quantity</th>
                                <th className="text-center">V. Weight (Kg)</th>
                                <th className="text-center">C. Weight (Kg)</th>
                                <th className="text-center">
                                  Effective Weight (Kg)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {shipment.Cargos.map((cargo, i) => (
                                <tr key={i}>
                                  <td className="text-center">{i + 1}</td>
                                  <td className="text-center">
                                    {cargo.Description}
                                  </td>
                                  <td className="text-center">
                                    {cargo.Weight}
                                  </td>
                                  <td className="text-center">
                                    {cargo.Quantity}
                                  </td>
                                  <td className="text-center">0.00</td>
                                  <td className="text-center">
                                    {cargo.Weight}
                                  </td>
                                  <td className="text-center">
                                    {cargo.Weight}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="waybill__cargo-text grid-full">
                          All weights are the estimates you provided and are
                          subject to verification and reweight.
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="waybill__activity-log">
                    <p className="waybill__heading">Shipment Activity Log</p>
                    <div className="waybill__alert">
                      <div className="waybill__alert-icon">
                        <IonIcon icon={informationCircle} />
                      </div>
                      <p>All activities are logged for this shipment</p>
                    </div>
                    <div className="table-responsive table-responsive--1 table-responsive--scroll">
                      <table className="table table--bordered table--2 table--scroll">
                        <thead>
                          <tr>
                            <th className="text-center">Date</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Activity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shipment.ActivityLogs.map((activityLog, i) => {
                            const [date, time] = getDateTime2(
                              activityLog.DateUpdated
                            );

                            return (
                              <tr key={i}>
                                <td className="text-center">
                                  <div>
                                    <p>{date}</p>
                                    <p>{time}</p>
                                  </div>
                                </td>
                                <td className="text-center">
                                  {activityLog.Status}
                                </td>
                                <td className="text-center">
                                  {activityLog.Activity}
                                  {activityLog.Activity ===
                                  "Shipment Canceled" ? (
                                    <>
                                      <br />
                                      (Reason: {activityLog.CancelReason})
                                    </>
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </main>
      </div>
    </>
  );
};

export default Tracking;

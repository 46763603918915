import { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { tRootState } from "../../store";

import dmxLogo from "../../assets/img/dmx-logo-colored.png";

const Home = () => {
  const navigate = useNavigate();

  const shipments = useSelector((state: tRootState) => state.cache.shipments);

  const [shipmentId, setShipmentId] = useState("");

  const handleTracking = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!shipmentId) return;

    navigate(`/${shipmentId}`);
  };

  return (
    <div className="auth auth--1">
      <div className="auth__right">
        <div className="auth__main auth__main--1">
          <div className="auth__logo-box">
            <img src={dmxLogo} alt="" className="auth__logo auth__logo--sm" />
          </div>
          {shipments.length ? (
            <div className="auth__btns">
              <Link to="/" className="auth__btn auth__btn--active">
                New Tracking
              </Link>
              <Link
                to={`/${shipments[0].TrackingNumber}`}
                className="auth__btn"
              >
                Shipment
              </Link>
            </div>
          ) : null}
          <div className="auth__header">
            <h3 className="auth__heading">Track a new shipment</h3>
            <p className="auth__sub-heading">
              Enter your shipment id below to track shipment
            </p>
          </div>
          <form className="auth__form" onSubmit={handleTracking}>
            <div className="form-group">
              <label>Shipment ID</label>
              <input
                type="text"
                placeholder="Enter shipment id"
                className="form-input"
                value={shipmentId}
                onChange={(e) => setShipmentId(e.target.value)}
              />
            </div>
            <div className="text-right">
              <button
                className="button mt-small"
                disabled={!shipmentId}
                style={{ padding: "0 3.6rem" }}
              >
                Track Shipment
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
